import axios from '@/libs/api.request'

export const UserLogfindPage = (pageNum, pageSize, entity) => {
  let uri = '/userlog/page/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'post',
    data: entity
  })
}

export const giveVipLog = (pageNum, pageSize, packageKindName) => {
  let uri = '/user/giveVipList/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'get',
    params: { packageKindName: packageKindName }
  })
}
